import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/images/logo_.png';
import callicon from '../assets/images/call_icon.png';
import whatsappsicon from '../assets/images/whatapps_icon.png';
import one from '../assets/images/1.png';
import downArrow from '../assets/images/downArrow.png';
import '../App.css';
import { connect } from "react-redux";

class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileNumber: '',
            passCode: '',
            branchDetails: {},
            billDetails: {},
            isPassCodeAvailabale: false,
            isLoading: false,
            isFetchingDetails: false,
            isShowingVeriView: false,
            viewBtnClass: 'btn-block btn btn-default viewBtn',
            isError: false,
            error: null,
            errorMsg: ''
        };
        // console.log('');
    }

    componentDidMount() {
        this.props.clearBill();
        this.props.clearBranch();
        this.getMbillShopDetailsFromServer();
    }

    showVerificationView = (e) => {
        e.preventDefault();
        this.setState({
            isShowingVeriView: true
        })
    }

    hideVerificationView = (e) => {
        e.preventDefault();
        this.setState({
            isShowingVeriView: false
        })
    }

    setViewButtonClass(actionStatus) {
        if (actionStatus) {
            this.setState({
                viewBtnClass: 'btn-block btn btn-default viewBtnClicked'
            })
        } else {
            this.setState({
                viewBtnClass: 'btn-block btn btn-default viewBtn'
            })
        }
    }

    whatsappClicked = (e) => {
        e.preventDefault();
        const whatsappUrl = 'https://wa.me/91' + this.state.branchDetails.WhatsAppNumber + '?text=Hi, Please let me know the status of my device';
        window.open(whatsappUrl, "_blank");
    }

    callClicked = (e) => {
        e.preventDefault();
        const callUrl = 'tel://' + this.state.branchDetails.BranchContact;
        window.open(callUrl, "_blank");
    }

    fetchBillDetails = (e) => {
        e.preventDefault();
        this.setState({
            isFetchingDetails: true
        });
        this.setViewButtonClass(true);
        axios({
            method: "POST",
            url: 'https://servicenear.in/mbillapi/api/Mbill/CheckCustomerPasscode',
            data: {
                "CustomerMobile": this.state.mobileNumber,
                "Passcode": this.state.passCode
            }
        }).then(response => {
            this.setViewButtonClass(false);
            if (response && response.status === 200 &&
                response.data && response.data.Result === 'Success') {

                if (response.data.ResponseData && response.data.ResponseData.ReturnData && response.data.ResponseData.ReturnData.length > 0 && response.data.ResponseData.ReturnData[0] !== null) {
                    this.setState({
                        billDetails: response.data.ResponseData.ReturnData[0],
                        isFetchingDetails: false,
                        isLoading: false,
                        isError: false,
                        errorMsg: ''
                    });
                    this.props.saveBill(this.state.billDetails);
                    // this.props.history.push({
                    //     pathname: '/mbill',
                    //     state: this.state.billDetails
                    // });
                    this.props.history.push('/mbill');
                } else {
                    this.setState({
                        billDetails: {},
                        isFetchingDetails: false,
                        isLoading: false,
                        isError: true,
                        errorMsg: 'Please check the mobile number filled'
                    });
                }
            } else {
                this.setState({
                    billDetails: {},
                    isFetchingDetails: false,
                    isLoading: false,
                    isError: true,
                    errorMsg: 'Something went wrong, try after sometime.'
                });
            }
        })
            .catch(error => {
                this.setViewButtonClass(false);
                this.setState({ error, isLoading: false, isFetchingDetails: false, errorMsg: 'Something went wrong, try after sometime.' })
            });
    }

    getMbillShopDetailsFromServer() {
        const url = new URL(window.location.href);
        const keyValue = url.searchParams.get("Key");
        if (keyValue && keyValue !== ' ') {
            this.setState({
                passCode: keyValue,
                isPassCodeAvailabale: true,
                isLoading: true
            });
            const requestUrl = 'https://servicenear.in/mbillapi/api/Mbill/GetShopNameByPasscode?Passcode=' + keyValue;
            axios(requestUrl)
                .then(response => {
                    if (response && response.status === 200 &&
                        response.data && response.data.Result === 'Success') {
                        if (response.data.ResponseData) {
                            this.setState({
                                branchDetails: response.data.ResponseData,
                                isLoading: false,
                                isError: false,
                                errorMsg: ''
                            });
                            this.props.saveBranch(response.data.ResponseData);
                        } else {
                            this.setState({
                                billDetails: {},
                                isLoading: false,
                                isError: true,
                                errorMsg: 'Please fill correct mobile number'
                            });
                        }
                    } else {
                        this.setState({
                            billDetails: {},
                            isLoading: false,
                            isError: true,
                            errorMsg: 'Something went wrong, try after sometime.'
                        });
                    }
                })
                .catch(error => this.setState({ error, isLoading: false }));
        } else {
            this.setState({
                isPassCodeAvailabale: false,
                isLoading: false,
            });
        }
    }

    /* html content */
    render() {
        return (
            <div className="App">
                <nav className="navbar navbar-default navbar-fixed-top" role="navigation">
                    <div className="container">
                        <div className="navbar-header page-scroll">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="navbar-brand page-scroll" href="#page-top">
                                marketnear.in
                            </a>
                        </div>

                        <div className="collapse navbar-collapse navbar-ex1-collapse">
                            <ul className="nav navbar-nav navbar-right">
                                <li className="hidden">
                                    <a className="page-scroll" href="#page-top"></a>
                                </li>
                                <li>
                                    <a className="page-scroll" href="#home">Home</a>
                                </li>
                                <li>
                                    <a className="page-scroll" href="#mbill" ng-click="goto(mbill)">M-Bill</a>
                                </li>

                                <li>
                                    <a className="page-scroll" href="#benefits">benefits Of M-Bill</a>
                                </li>
                                <li>
                                    <a className="page-scroll" href="#contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <section className="home_section txtcntr" id="home">
                    <div className="container">
                        <figure className="col-md-6">
                            <img src={logo} alt="" className="hidden-md hidden-lg" width="80" />
                            <img src={logo} alt="" className="hidden-sm hidden-xs" width="150" />
                            <p>marketnear.in</p>
                            <figcaption>
                                <h2>
                                    <b className="whiteColour">Move to m-bill</b>
                                    <br />
                                    <b className="bcd53aColour">Save tree</b>
                                    <br />
                                </h2>
                            </figcaption>
                        </figure>
                        <form className="col-md-6 loginFormCrtl" name="loginForm" onSubmit={this.fetchBillDetails}>
                            {this.state.isPassCodeAvailabale ? (
                                <span>
                                    {this.state.isLoading ? (
                                        <div className="loginForm">
                                            <div className="loginFormLoading">Loading your bill details ...</div>
                                        </div>
                                    ) : (
                                        <span>
                                            <div className="loginForm">
                                                <div className="row shopDetailsContiner">
                                                    <div className="col-xs-3 shopDetailsIcon">
                                                        <img alt="" src={this.state.branchDetails.BranchLogo} width="100%" />
                                                    </div>
                                                    <div className="col-xs-9 shopDetails">
                                                        <div className="shopDetailsName">{this.state.branchDetails.BranchName}</div>
                                                        <div className="shopDetailsAddress">{this.state.branchDetails.Place}{this.state.branchDetails.BranchAddress ? (<span> - {this.state.branchDetails.BranchAddress}</span>) : (<span></span>)}</div>
                                                        <div className="shopDetailsPhone">M: <span className="fontWeight600">{this.state.branchDetails.BranchContact}</span></div>
                                                    </div>
                                                </div>
                                                {!this.state.isShowingVeriView ? (
                                                    <span>
                                                        <div className="loginformDivivder"></div>
                                                        <div className="billDetailsNavi row">
                                                            <div className="col-xs-9 ">
                                                                View your service related details of your device like status, warranty etc.
                                                            </div>
                                                            <div className="col-xs-3">
                                                                <div onClick={this.showVerificationView} className="billDetailsNaviButton billDetailsVertical">
                                                                    <span className="glyphicon glyphicon-menu-right arrow" aria-hidden="true"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <div className="col verificationPanel">
                                                            <div className="col">
                                                                <input type="text" value={this.state.mobileNumber} onChange={(e) => this.setState({ mobileNumber: e.target.value, isError: false })} className="form-control txtcntr" placeholder="Mobile Number" required />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-xs-4">
                                                                    <button onClick={this.hideVerificationView} className="btn-block btn btn-default cancelBtn">Cancel</button>
                                                                </div>
                                                                <div className="col-xs-8">
                                                                    <button className={this.state.viewBtnClass} id="submit" >View {this.state.isFetchingDetails ? (<i className="fa fa-spinner fa-spin activityIndicator"></i>) : (<span></span>)}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>)}
                                            </div>
                                            {!this.state.isShowingVeriView ? (
                                                <div className="dialPanel row">
                                                    <div onClick={this.whatsappClicked} className="col-xs-6 dialButtonContainer">
                                                        <div className="col-xs-3 dialButtonWhatsappIcon">
                                                            <img alt="" src={whatsappsicon} width="25" />
                                                        </div>
                                                        <div className="col-xs-7 dialButtonWhatsapp">Whatsapp</div>
                                                    </div>
                                                    <div onClick={this.callClicked} className="col-xs-6 dialButtonContainer callButton">
                                                        <div className="col-xs-5 dialButtonCallIcon">
                                                            <img alt="" src={callicon} width="25" />
                                                        </div>
                                                        <div className="col-xs-7 dialButtonCall">Call</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </span>
                                    )}
                                </span>
                            ) : (
                                <div className="loginForm loginFormWithPasscode">
                                    <div className="col nonPasscodePanel ">
                                        <div className="col heading">
                                            <h3>Fill the details</h3>
                                            <p>To view your device status and service details</p>
                                        </div>
                                        <div className="col">
                                            <input type="text" value={this.state.mobileNumber} onChange={(e) => this.setState({ mobileNumber: e.target.value, isError: false })} className="form-control txtcntr" placeholder="Mobile Number" required />
                                        </div>
                                        <div className="col">
                                            <input type="text" value={this.state.passCode} onChange={(e) => this.setState({ passCode: e.target.value, isError: false })} className="form-control txtcntr" placeholder="Passcode" />
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <button className={this.state.viewBtnClass} id="submit" >View {this.state.isFetchingDetails ? (<i className="fa fa-spinner fa-spin activityIndicator"></i>) : (<span></span>)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="loginformDivivder"></div>
                                    <div className="billDetailsNavi row">
                                        <div className="col-xs-12">
                                            <p className="msg">Please fill the mobile number in which you received the message from the shop regarding you device service and also file the code present in the same meassage, to view the details</p>
                                            <p className="msg">Or</p>
                                            <p className="msg">Tap or click on the link in the message received from shop or service center</p>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </form>
                        <a href="#mbill" className="scrollDown page-scroll col-md-12">
                            <p className="whiteColour f16">Why to fill ? & How to fill ?</p>
                            <img alt="" src={downArrow} width="50" />
                        </a>
                    </div>
                </section>
                <section className="mbill_section txtcntr" id="mbill">
                    <div className="container">
                        <div className="head_Caption">
                            <h2>M-BILL</h2>
                            <p>mobile sms billing system</p>
                        </div>
                        <div className="col-md-6">
                            <p className="pAlign">M-bills is mainly designed to move  all our invoicing to digital billing - enabling customers to receive bills in mobile as sms, which leads to less usage of paper. Less usage of paper will directly save trees which help us to save our earth from global warming. Financially digital bills are more cheaper than paper bills which ultimately increases the profits of your business. As bills increases the more billing expense you save. You can avoid prints, paper rolls , printer maintenance, printer power consumption etc…. By providing m-bills, customer no need to handle or keep the bill safe for future reference or warranty. As the bills are Mobile optimised  they can  access from any where and any time through their mobiles which increases the customer experiences. A
                                customized SMS billing platform to satisfy your needs Enhance your mobile payment channel with our best
                                in class billing product. Easily customized, direct and automated.</p>
                        </div>
                        <figure className="col-md-6">
                            <img alt="" src={one} width="300" />
                        </figure>
                    </div>
                </section>
                <section className="benefitsofMbill_section txtcntr" id="benefits">
                    <div className="container">
                        <div className="head_Caption">
                            <h2>BENEFITS OF M-BILL</h2>
                            <p>mobile sms billing system</p>
                        </div>
                        <div className="col-md-4 col-xs-12 txtlft content">

                            <h1>1<span>M-BILL</span></h1>
                            <p className="pAlign">M-bills is mainly designed to move  all our invoicing to digital billing - enabling customers to receive bills in mobile as sms, which leads to less usage of paper. Less usage of paper will directly save trees which help us to save our earth from global warming. </p>
                        </div>
                        <div className="col-md-4 col-xs-12 txtlft content">

                            <h1>2<span>M-BILL FINANCIAL BENEFIT</span></h1>
                            <p className="pAlign">Financially digital bills are more cheaper than paper bills which ultimately increases the profits of your business. As bills increases the more billing expense you save. You can avoid prints, paper rolls , printer maintenance, printer power consumption etc….</p>
                        </div>
                        <div className="col-md-4 col-xs-12 txtlft content">

                            <h1>3<span>M-BILL CUSTOMER BENEFIT</span></h1>
                            <p className="pAlign">By providing m-bills, customer no need to handle or keep the bill safe for future reference or warranty. As the bills are Mobile optimised  they can  access from any where and any time through their mobiles which increases the customer experiences.</p>
                        </div>
                    </div>
                </section>
                <section className="contact_section txtcntr" id="contact">

                    <div className="container">
                        <div className="head_Caption">
                            <h2>CONTACT Q5SOFT</h2>
                        </div>

                        <div className="col-xs-12 col-md-6 txtlft address">
                            <h2>MOB: 9633 22 33 55</h2>
                            <h3>Email: info@q5soft.com</h3>
                            <h4>marketnear.in</h4>
                            <h4>Powered by</h4>
                            <h2>Q5soft</h2>
                            <p>2nd Floor,Peejay Tower,Pallimoola ,Thrissur</p>
                            <h3><a href='https://wwww.q5soft.com'>www.q5soft.com</a></h3>
                        </div>

                        <form className="col-xs-12 col-md-6 submitquery" >
                            <h4>Sumbit Your Query</h4>
                            <input type="text" className="textField" placeholder="Email" />
                            <input type="text" className="textField" placeholder="Message" />
                            <button>SEND</button>
                        </form>
                    </div>

                </section>
                <footer>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/qfivesoft/">
                                <i className="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/q5soft-india-09a214109/">
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/q5soft">
                                <i className="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/qfivesoft/">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        billDetails: state.billDetailsObj
    };
};

const mapDispachToProps = dispatch => {
    return {
        saveBill: (data) => dispatch({ type: "SAVE_BILL", value: data }),
        clearBill: () => dispatch({ type: "CLEAR_BILL", value: '' }),
        saveBranch: (data) => dispatch({ type: "SAVE_BRANCH", value: data }),
        clearBranch: () => dispatch({ type: "CLEAR_BRANCH", value: '' })
    };
};

export default withRouter(connect(mapStateToProps, mapDispachToProps)(LandingPage));
