import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import '../App.css';
import whatsappicon from '../assets/images/whatsappicon.png';
import callicon from '../assets/images/callicon.png';
import Sidebar from './Sidebar.js';
import { connect } from "react-redux";
import Moment from 'moment';

class MBillDetails extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.billDetails.WorkCode) {
            this.props.history.push('/');
        }
    }

    whatsappClicked = (e) =>  {
        e.preventDefault();
        const whatsappUrl = 'https://wa.me/91' + this.props.branchDetails.WhatsAppNumber + '?text=Hi, Please let me know the status of my device';
        window.open(whatsappUrl,"_blank");
    }

    callClicked = (e) => {
        e.preventDefault();
        const callUrl = 'tel://' + this.props.branchDetails.BranchContact;
        window.open(callUrl,"_blank"); 
    }

    /* html content */
    render() {
        Moment.locale('en');
        return (
            <div className="App" id="outer-container">
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} name={this.props.billDetails.ContactPerson} number={this.props.billDetails.ContactNo} />
                <div className="container-fluid" id="page-wrap">
                    <div className="row" style={{ minHeight: "70px", height: "10vh", backgroundColor: "#4E9F3D" }} >
                        <div className="col-xs-2 menuButton">
                        </div>
                        <div className="col-xs fontCustom colorwhite" style={{ height: "100%" }}>
                            <div className="row menuButton">
                                <div className="col font24 fontWeight600 menuHeader">Mbill</div>
                                <div className="col font10 fontWeight600 menuSubHeader">Powered by Q5soft</div>
                            </div>
                        </div>
                    </div>
                    <div className="row fontCustom billContent">
                        <div className="col billContainer">
                            <div className="row billAddressHeader">
                                <div className="col font18 fontWeight600 billBranchName">{this.props.branchDetails.BranchName}</div>
                                <div className="col font10 fontWeight500 billBranchAddress">{this.props.branchDetails.BranchAddress ? (this.props.branchDetails.BranchAddress + ',') : ('')}{this.props.branchDetails.BranchPlace}</div>
                                <div className="col font10 fontWeight500">
                                    <div className="row">
                                        <div className="col-xs-6 fontWeight600 billContacts">{this.props.branchDetails.BranchContact}</div>
                                        <div className="col-xs-6">
                                            <div className="row">
                                                {this.props.branchDetails.WhatsAppNumber ? (
                                                    <div onClick={this.whatsappClicked} className="col-xs-6 nopadding billbutton billbuttonWhatsapp ">Whatsapp
                                                        <img className="billbuttonWhatsappIcon" src={whatsappicon} alt="Logo" />
                                                    </div>
                                                ) : (
                                                    <div></div>)}
                                                     {this.props.branchDetails.BranchContact ? (
                                                <div onClick={this.callClicked} className="col-xs-6 nopadding billbutton billbuttonCall ">Call
                                                    <img className="billbuttonCallIcon" src={callicon} alt="Logo" />
                                                </div>
                                                     ) : (<div></div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row billHeader">
                                <div className="col-xs-6">
                                    <div className="row" >
                                        <div className="col-xs-4 font10 nopadding">Service job no.</div>
                                        <div className="col-xs-8 font16 fontWeight600 nopadding" style={{ textAlign: "left" }}>{this.props.billDetails.WorkCode}</div>
                                    </div>
                                </div>
                                <div className="col-xs-6 font12 fontWeight600 nopadding" style={{ textAlign: "right" }}>
                                    {Moment(this.props.billDetails.TrackDate).format('DD/MMM/yyyy hh:mm a')}
                                </div>
                            </div>
                            <div className="row billCustomerDetails">
                                <div className="col">
                                    <div className="row ">
                                        <div className="col-xs-5">Customer name</div>
                                        <div className="col-xs-7 fontWeight600">: {this.props.billDetails.ContactPerson}</div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-xs-5">Customer Ph No.</div>
                                        <div className="col-xs-7 fontWeight600">: {this.props.billDetails.ContactNo}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row billJobDetails">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-xs-5">Device Details</div>
                                        <div className="col-xs-7 fontWeight600">: {this.props.billDetails.Manufacture ? (this.props.billDetails.Manufacture) : ('')} {this.props.billDetails.ModelName ? (this.props.billDetails.ModelName) : ('')}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-5">Complaint</div>
                                        <div className="col-xs-7 fontWeight600">: {this.props.billDetails.WorkDescription}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-5">Device condition</div>
                                        <div className="col-xs-7 fontWeight600">: {this.props.billDetails.CustomerDescription ? (this.props.billDetails.CustomerDescription) : ('-')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row billJobStatus">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-xs-4">Job Status :</div>
                                        <div className="col-xs-8">
                                            <div className="billJobStatusItem">{this.props.billDetails.WorkStatus}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row billJobEstimations">
                                <div className="col-xs-6">
                                    <div className="row">
                                        <div className="col-xs">Delivery Date</div>
                                        <div className="col-xs fontWeight600">{Moment(this.props.billDetails.DeliveryDate).format('DD/MMM/yyyy hh:mm a')}</div>
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <span>{this.props.billDetails.AdvanceAmount ? (
                                        <div className="row">
                                            <div className="col-xs">Advanced amount paid</div>
                                            <div className="col-xs fontWeight600">Rs. 1000</div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}</span>
                                    <span>{this.props.billDetails.BalanceAmount ? (
                                        <div className="row">
                                            <div className="col-xs">Balance amount paid</div>
                                            <div className="col-xs fontWeight600">Rs. 500</div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}</span>
                                    <span>{this.props.billDetails.BalanceAmount ? (
                                        <div className="row">
                                            <div className="col-xs">Total amount paid</div>
                                            <div className="col-xs fontWeight600">Rs. 1500</div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}</span>
                                </div>
                            </div>
                            <div className="row billFooter">
                                <div className="col">
                                    Authorised person : Manager, {this.props.billDetails.BranchName}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        billDetails: state.billDetailsObj,
        branchDetails: state.branchDetailsObj
    };
};


export default withRouter(connect(mapStateToProps)(MBillDetails));
