import React, { Component } from "react";
import Sidebar from './Sidebar.js';

class FAQ extends Component {
    
    /* html content */
    render() {
        return (
            <div className="App" id="outer-container">
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                <div className="container-fluid" id="page-wrap">
                    <div className="row" style={{ minHeight: "70px", height: "10vh", backgroundColor: "#4E9F3D" }} >
                        <div className="col-xs-2 menuButton">
                        </div>
                        <div className="col-xs fontCustom colorwhite" style={{ height: "100%" }}>
                            <div className="row menuButton">
                                <div className="col font24 fontWeight600 menuHeader">Mbill</div>
                                <div className="col font10 fontWeight600 menuSubHeader">Powered by Q5soft</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h2>FAQ</h2>
                            <p>mobile sms billing system</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h3>1. <span>Can I access my bills from anywhere at anytime?</span></h3>
                            <p>Yes Of course.. you can access the bills from anywhere and at any time with the availability of internet connectivity.</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">

                            <h3>2. <span>Who can view the bills?</span></h3>
                            <p>Those who receives the pass code has given the access of bills.</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">

                            <h3>3. <span>How do I use these bills for warranty, guarantee, and complain purposes?</span></h3>
                            <p>You can use this bills like any other previously used paper bills.. MBill is the digitized version of the normal paper bills.</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">

                            <h3>4.<span>Is there any loss for the customer when receives the bills each time?</span></h3>
                            <p>No, we do not support any kind of charge to use our services … all services are absolutely free of cost.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FAQ;