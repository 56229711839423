import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import LandingPage from './components/LandingPage';
import MBillDetails from './components/MBillDetails';
import FAQ from "./components/FAQ";
import About from "./components/About";

class App extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={LandingPage} />
                    <Route path="/mbill" exact render={(props) => (
                        <MBillDetails {...this.props} />)} />
                    <Route path="/about" exact component={About} />
                    <Route path="/faq" exact component={FAQ} />
                </Switch>
            </Router>
        );
    }
}

export default App;
