import React, { Component } from "react";
import Sidebar from './Sidebar.js';

class About extends Component {

    /* html content */
    render() {
        return (
            <div className="App" id="outer-container">
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                <div className="container-fluid" id="page-wrap">
                    <div className="row" style={{ minHeight: "70px", height: "10vh", backgroundColor: "#4E9F3D" }} >
                        <div className="col-xs-2 menuButton">
                        </div>
                        <div className="col-xs fontCustom colorwhite" style={{ height: "100%" }}>
                            <div className="row menuButton">
                                <div className="col font24 fontWeight600 menuHeader">Mbill</div>
                                <div className="col font10 fontWeight600 menuSubHeader">Powered by Q5soft</div>
                            </div>
                        </div>
                    </div>
                    <div className="row fontCustom">
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h2>About Us</h2>
                            <p>mobile sms billing system</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h4 >M-BILL</h4>
                            <p className="text-left">M-bills is mainly designed to move  all our invoicing to digital billing - enabling customers to receive bills in mobile as sms, which leads to less usage of paper. Less usage of paper will directly save trees which help us to save our earth from global warming.</p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h4 >M-BILL Financial Benefit</h4>
                            <p className="text-left">Financially digital bills are more cheaper than paper bills which ultimately increases the profits of your business. As bills increases the more billing expense you save. You can avoid prints, paper rolls , printer maintenance, printer power consumption etc… </p>
                        </div>
                        <div className="col-md-4 col-xs-12 text-left content">
                            <h4 ><span>M-BILL Customer’s Benefit</span></h4>
                            <p className="text-left">By providing m-bills, customer no need to handle or keep the bill safe for future reference or warranty. As the bills are Mobile optimised  they can  access from any where and any time through their mobiles which increases the customer experiences.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;