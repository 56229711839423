import React from 'react';
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu';
import '../App.css';

export default props => {
    return (
        <Menu>
            <div className="App">
                <div className="container-fluid fontCustom">
                    <div className="col bmmenuHeader">
                        <div className="row">
                            <div className="col font18 fontWeight600">
                                Hi
                            </div>
                            <div className="col font16 fontWeight600">
                                {props.name}
                            </div>
                            <div className="col font12 fontWeight600">
                                {props.number}
                            </div>
                        </div>
                    </div>
                    <div className="col bmmenuItem">
                        <Link to="/mbill">
                            <div className="row bmmenuItemRow">
                                <div className="col-xs-10">MBill Detail</div>
                                <div className="col-xs-2">
                                    <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col bmmenuItem">
                        <Link to="/about">
                            <div className="row bmmenuItemRow">
                                <div className="col-xs-10">About Us</div>
                                <div className="col-xs-2">
                                    <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col bmmenuItem">
                        <Link to="/faq">
                            <div className="row bmmenuItemRow">
                                <div className="col-xs-10">FAQ</div>
                                <div className="col-xs-2">
                                    <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col bmmenuItemNoBorder">
                        <Link to="/">
                            <div className="row bmmenuItemRow">
                                <div className="col-xs-10">
                                    Logout
                                </div>
                                <div className="col-xs-2">
                                    <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col bmmenuItemNoBorder">
                      Version_V.1.5_22032301
                    </div>
                </div>
            </div>
        </Menu>
    );
};
